<template>
    <v-main>
        <Header/>
        <div class="section6">

       <v-row>
                <v-col>
                    <div class="aurora2">

        <v-img max-height="30em" min-height="20em" contain :src="require('../assets/Resized Pictures/_DSC4727.webp')">
         <!-- <v-card-title  class="justify-center"  style="margin-top:5em;"  primary-title>
             <h1>
             System Development
             </h1>
         </v-card-title> -->
         </v-img> 

             </div>
                </v-col>
            </v-row>
        <v-container>
           <v-row>
            <v-card-title style="margin-bottom:1em" primary-title>  
                  <h2>
                     System Development
                  </h2>
            <br>
            </v-card-title>
                   <div class="space">
               <v-col>
                       

        <p>
            The Code Effect produces top-notch customisable stand-alone Desktop system development services. We ensure high product functionality regardless of its complexity. Delivering a much easier product and an exceptional user experience. <br> Our expertise is exhibited in our systems; where  computationally expensive processes, state-of-art graphics, computer vision capabilities ,top performance, utility of hardware access and well founded security are achieved.
        </p>
               </v-col>
                   </div>
           </v-row>
        </v-container>



        <SystemCards/>
       
       
       
       
       
        <v-container>
            <v-row>
              <div class="space">
                <v-col>
                    <p>Upgrading to having your own business stand-alone system will give you the privilege of automation of the organisation's tasks as well as reporting the progress or lags in the organisation's activities. This improves company's efficiency and effectiveness of the company's activities. The software reduces the workload and automation of activities.</p>
                </v-col>
              </div>
            </v-row>

            <!-- customer benifits -->
 
            
             <v-row class="justify-center">
                    <!-- <v-col>
                        <div class="round">

                        <v-img v-tilt="{glare:true, reverse: true}" :src="require('../assets/samuel-ferrara-uOi3lg8fGl4-unsplash.jpg')" height="200px" width="200px"></v-img>
                        </div>
                        <div class="round">
                            <v-img v-tilt="{glare:true, reverse: true}" :src="require('../assets/fish Website Pictures/caspar-camille-rubin-89xuP-XmyrA-unsplash.jpg')" height="300px" width="200px"></v-img>
                        </div>
                    </v-col> -->
                        <v-card-title  style="word-break: normal; margin-top:1.5em;">
                    <h2>
                    Our System Development will ensure you:
                    </h2>
                    </v-card-title> 
                <v-col cols="12" lg="6" md="8" sm="8">
                <!-- <div class="space" stylße="background-color:rgba(0,0,255,0.02);"> -->
                <div class="aurora2">
                    <v-card-text  >
            
                        <ul >
                            <li>
                               <p>
                                     Improve staff efficiency
                                   </p>
                                    </li>
                        <li>
                            <p>
                        Cut cost by automating routine tasks
                            </p>
                        </li>
                        <li>
                              <p>
                         Measure and increase office productivity
                          </p>
                        </li>
                        <li>
                              <p>
                        Streamline business operations and accounts
                            </p>
                            </li>
                        <li>
                              <p>
                       Replace paper processes
                         </p>
                            </li>
                            <li>
                              <p>
                       Communicate more efficiently with customers, suppliers and partners
                         </p>
                            </li>
                        </ul>
                        </v-card-text>   
                </div>
                <!-- </div> -->
                    </v-col>
            </v-row>
                <!-- </div> -->
        </v-container>
        <v-container>
            <v-row>
                <v-col>

            
        <v-container>
            <v-row>
                <v-col>
                    <h1>
                        Technologies Used:
                    </h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-for="item in items" :key="item.id">
                    <div class="space" v-tilt="{glare:true, reverse: true}">
                        <v-lazy>

                    <v-img class="icon" contain height="auto" width="auto" max-height="13em" max-width="6em" :src='item.icon'>
                        </v-img>
                        </v-lazy>
                    </div>
                </v-col>
            </v-row>
        </v-container>
                </v-col>
                <v-col>

        <v-container>
            <v-row>
                <v-col>
                    <h1>
                        We're experienced in building:
                    </h1>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            
        <!-- </v-container> -->
            <v-row>
                    <div class="space"  >
                <v-col>
                        <ul>
                            <li>
                                Point of Sale systems
                            </li>
                            <li>
                                billing & invoicing systems
                            </li>
                            <li>
                                Inventory software
                            </li>
                            <li>
                                Business process optimisation software
                            </li>
                            <li>
                                Supervisory control system
                            </li>
                            <li>
                                VoIP software
                            </li>
                            <li>
                                Trading software
                            </li>
                            <li>
                                General ledger software
                            </li>                               
                        </ul>
                </v-col>
                    </div>
            </v-row>
        </v-container>

                </v-col>
    </v-row>
        </v-container>


        
         <Footer/>   
        </div>

    </v-main>
</template>
<script>
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
import SystemCards from '../components/SystemCards.vue'
// import nodejs from '../assets/icons/nodejs.svg'

export default {
  components: { SystemCards, Header,Footer },
    name: 'System',
    data() {
        return {
            items:[
          {
            id:1,
            icon: require('../assets/icons/HTML.svg'),
            // color:'orange darken-2'
          },
          {
            id:2,
            icon: require('../assets/icons/CSS.svg'),
            color:'blue-grey darken-2'
          },
          {
            id:3,
            icon: require('../assets/icons/JS.svg'),
            color:'orange darken-2'
          },
          {
            id:4,
            icon: require('../assets/icons/electronjs.svg'),
            color:'teal'
          },
          {
            id:5,
            icon: require('../assets/icons/SASS.svg'),
            color:'primary'
          },
          {
            id:6,
            icon: require('../assets/icons/Vue.svg'),
            color:'primary'
          },
          {
            id:7,
            icon: require('../assets/icons/vuetifyjs.svg'),
            color:'primary'
          },
          {
            id:8,
            icon: require('../assets/icons/bootstrap.svg'),
            color:'primary'
          }
        ]
            
        }
    },
    
    metaInfo: {
      title: 'System Development Services',
      content: 'System Development Company based in Sudan. The Code Effect offers Tailored System Development Services for maximum business process optimization. Custom Business System Development, Point of Sale systems, Accounting Systems ,and Cross-Platform Development Service.'
    },
    
}
</script>
<style lang="postcss">
/* List */
 @import '../sass/list.sass'
</style>