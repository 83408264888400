<template>
    <v-main >
         <v-container>
  
                <v-row>

                   <v-col lg="4" md="6" sm="12" v-for="item in items" :key="item.id">
                     <v-lazy transition="scroll-x-transition">

     <div class="space">
                         <v-container >
                          <!-- <div class="cards"> -->
                            <v-card color="rgba(0,0,0,0)">
                              

                     <!-- <v-icon :color="item.color"> -->
                     <v-card-title style="word-break: normal;">
                        <v-img class="icon" contain height="auto" width="auto" max-height="7em" max-width="3em" :src='item.icon'>
                        </v-img>
                        <!-- </v-icon> -->
                     
                         {{item.title}}
                     </v-card-title>
                     <v-card-text style="word-break: normal;">
                        <p>
                           {{item.p}}
                          </p>
                     </v-card-text>
                          <!-- </div> -->
                            </v-card>
                      </v-container>
                  
         </div>
                     </v-lazy>
         </v-col>
             
        </v-row>
        </v-container>
    </v-main>

</template>
<script>
export default {
    name:'SystemCards',
    data() {
        return {
             items:[
          {
            id:1,
            icon: require('../assets/icons/services/016-cycle.svg'),
            title: "Managed SDLC"  ,
            p: 'transforming complex problems &  ideas into reliable functional operational structure . Our team is committed to each development phase. Analyzing potential requirements and determining them, designing a blueprint for the solution, and implementing that blueprint using the right technology while always testing as a second instinct is what builds the confidence of our clients.'
          },
          {
            id:2,
            icon: require('../assets/icons/services/017-medal.svg'),
            title: 'Quality assurance' ,
            p:'monitoring the software engineering processes and methods used in each project to meet our tall standard with a keen-eye. Ensuring our clients that all work products, activities and processes comply with the predefined plans and quality strategies.'
          },
          {
            id:3,
            icon: require('../assets/icons/services/001-ui-design.svg'),
            title: 'UI/UX' ,
            p:' we match the design of the product with our client’s style. No matter how serious and professional or how carefree and joyful. If you want to hop into the next trend or want an old-school edgy website. Our design team got you covered'
          },
          {
            id:4,
            icon: require('../assets/icons/services/018-exam.svg'),
            title: 'Test Driven Development' ,
            p:'the assurance of our quality is the product of our continuous testing process that guarantees the functionality of our systems. '
          },
          {
            id:5,
            icon: require('../assets/icons/services/019-agile.svg'),
            title: 'Operational resilience and agility' ,
            p:'systems need both resilience and agility. They must ingeniously combine agile and resilient ways of development into their operating model, and the way they get constructed.We thrive through a combination of managing both.'
          },
          {
            id:6,
            icon: require('../assets/icons/services/015-cross-platform.svg'),
            title: 'Cross-platform development' ,
            p:'thanks to electron.js now we are capable of delivering light desktop applications that work on all three operating systems: microsoft windows, mac, and Linux using the same code for each. while still our quality.'
          }
             ]
        }
    },
}
</script>